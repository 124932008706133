<template>
  <div>
    <v-toolbar elevation="0">
      <v-btn :to="{ name: 'configs.insurers_portal.users' }" exact icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ user.name }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card flat>
      <v-card-text>
        Status:
        <v-chip label x-small :color="user.is_active ? 'green' : 'red'" dark>
          {{ user.is_active ? "Ativo" : "Inativo" }}
        </v-chip>
        <br />
        Email: {{ user.email }} <br />
        Username: {{ user.username }} <br />
        Telemóvel: {{ user.phone_number || "---" }} <br />
        Idioma: {{ user.language_id }} <br />
        Pode utilizar o Portal HCM:
        {{ user.can_access_portal_hcm ? "Sim" : "Não" }}
      </v-card-text>
      <v-list>
        <v-list-item @click="remove()" class="red--text" link>
          <v-list-item-title>
            <v-icon color="red" left>mdi-close</v-icon> Remover da companhia
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>

    <ConfirmationDialog ref="confirmation" />
  </div>
</template>

<script>
import { show, remove } from "@/services/PortalCompanhias/insurer_users";
import ToastsMixin from "@/mixins/ToastsMixin";
import ConfirmationDialog from "@/components/tools/ConfirmationDialog.vue";

export default {
  mixins: [ToastsMixin],

  components: {
    ConfirmationDialog,
  },

  props: {
    id: [String, Number],
    user_id: [String, Number],
  },

  data() {
    return {
      loading: false,
      user: null,
    };
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        this.user = await show(this.id, this.user_id);
      } catch (error) {
        this.toastError("Falha ao carregar utilizador");
        this.$router.replace({
          name: "configs.insurers_portal.users",
        });
      } finally {
        this.loading = false;
      }
    },

    async remove() {
      this.$refs.confirmation.confirm(
        `Remover ${this.user.name} da companhia?`,
        async ({ startLoading, stopLoading, close }) => {
          startLoading();
          try {
            await remove(this.id, this.user_id);
            this.toast("Utilizador removido da companhia");
            this.$router.replace({
              name: "configs.insurers_portal.users",
            });
            close();
          } catch (error) {
            this.toastError(
              "Não foi possível remover o utilizador da companhia"
            );
          } finally {
            stopLoading();
          }
        },
        null,
        {
          color: "red",
          confirmText: "Remover",
          cancelText: "Manter utilizador",
        }
      );
    },
  },

  computed: {},

  created() {
    this.load();
  },
};
</script>
