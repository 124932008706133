import Vue from "vue";

export async function index(insurer_id) {
  const response = await Vue.prototype.$http.get(
    `/configs/insurers/${insurer_id}/users`
  );

  return response.data.data;
}

export async function indexAvailable(insurer_id) {
  const response = await Vue.prototype.$http.get(
    `/configs/insurers/${insurer_id}/users/available`
  );

  return response.data.data;
}

export async function show(insurer_id, user_id) {
  const response = await Vue.prototype.$http.get(
    `/configs/insurers/${insurer_id}/users/${user_id}`
  );

  return response.data.data;
}

export async function invite(insurer_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${insurer_id}/users/invite`,
    payload
  );

  return response.data.data;
}

export async function link(insurer_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/insurers/${insurer_id}/users/link`,
    payload
  );

  return response.data.data;
}

export async function remove(insurer_id, user_id) {
  const response = await Vue.prototype.$http.delete(
    `/configs/insurers/${insurer_id}/users/${user_id}`
  );

  return response;
}
